@import "./../App.scss";

.about-block {
    display: flex;
    position: relative;
    color: $light-text;
    margin-bottom: 50px;
    margin-top: 80px;

    @media screen and (max-width: 1000px){
        padding: 5%;
    }
}

.column.right {
    flex: 45%;
    margin-right: 5%;
    font-family: 'Open Sans', Sans-Serif, serif;
    font-size: 20px;
    align-self: center;
    max-height: 100%;
    text-align: left;
}

.big {
    font-family: 'Raleway', Sans-Serif, serif;
    color: $color-text;
    font-size: 40px;
    padding-bottom: 5px;
}

.column.left {
    flex: 55%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    justify-content: center;
    align-content: center;
}

.column.img {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}

.column img {
    margin-top: 8px;
    vertical-align: middle;
    max-height: 100%;
}

.column.right li {
    list-style: none;
}

.skill {
    color: $light-text;
    background-color: white;
    border-radius: 16px;
    padding: 8px;
    margin: 8px;
    display: inline-block;
    box-shadow: 0 2px 4px rgba(0,0,0,.16), 0 0 2px rgba(0,0,0,.08);
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.16), 0 0 2px rgba(0,0,0,.08);
}

.fade-in.four {
    -webkit-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;/* Firefox < 16 */
    -ms-animation-delay: 0.25s;/* Internet Explorer */
    -o-animation-delay: 0.25s;/* Opera < 12.1 */
    animation-delay: 0.25s;
}

.fade-in.five {
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;/* Firefox < 16 */
    -ms-animation-delay: 0.75s;/* Internet Explorer */
    -o-animation-delay: 0.75s;/* Opera < 12.1 */
    animation-delay: 0.75s;
}
