@import "./../App.scss";

nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    float: right;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-content: center;
    padding-top: 20px;
    z-index: 1;
}

nav a {
    color: $dark-text;
    text-decoration: none;
    padding: 5px 20px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    transition: .3s background-color;
    opacity: 0.3;
}

nav a:hover {
    /* background-color: white; */
    /* color: #3b9aff; */
    opacity: 1.0;
    transition: .3s opacity;
}

.active {
    opacity: 1.0;
    border-bottom: 3px solid $dark-text;
}

.title {
    display: block;
    color: $dark-text;
    float: left;
    font-family: "Roboto", sans-serif;
    padding: 5px 20px;
}