@import "./../App.scss";

.timeline-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 50%;

    @media screen and (max-width: 1000px) {
        margin: 0
    }
}

.timeline-item:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
}

.timeline-item-content {
    box-shadow: 0 2px 4px rgba(0,0,0,.3), 0 0 2px rgba(0,0,0,.08);
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.3), 0 0 2px rgba(0,0,0,.08);
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    width: 400px;
    max-width: 70%;
    text-align: right;
    color: #27233A;

    @media screen and (max-width: 1000px) {
        max-width: 95%;
    }
}

.timeline-item-content::after {
    content: ' ';
    background-color: white;
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -7.5px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content {
    text-align: left;
    align-items: flex-start;
}
.timeline-item:nth-child(odd) .timeline-item-content::after {
    right: auto;
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    position: absolute;
    text-transform: uppercase;
}
.timeline-item:nth-child(odd) .timeline-item-content .tag {
    left: auto;
    right: 5px;
}
.timeline-item-content time {
    font-size: 12px;
    font-weight: bold;
}
.timeline-item-content p {
    font-size: 16px;
    font-family: 'Open Sans', Sans-Serif;
    line-height: 24px;
    margin: 15px 0;
    max-width: 300px;
    color: $dark-text;
}
.timeline-item-content a {
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: $dark-text;
}

.timeline-item-content a:hover {
    text-decoration: underline;
}

.timeline-item-content a::after {
    content: ' ►';
    font-size: 12px;
}
.timeline-item-content .circle {
    background-color: white;
    border: 3px solid $color-text;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -40px;
    width: 20px;
    height: 20px;
    z-index: 100;
}
.timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -40px;
}

@media only screen and (max-width: 1000px) {
    .timeline-item-content .tag {
        width: calc(100% - 10px);
        text-align: center;
    }

    .timeline-item-content time {
        margin-top: 20px;
    }
}