@import "./../App.scss";

.round {
    border-radius: 16px;
}

.description {
    height: 100%;
    background-color: white;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.16), 0 0 2px rgba(0,0,0,.08);
    box-shadow: 0 2px 4px rgba(0,0,0,.16), 0 0 2px rgba(0,0,0,.08);
    text-align: left;
    padding: 30px 25px;
    color: rgba(0, 0, 0, .8);
    transition: height .25s ease-in-out;
    -webkit-transition: height .25s ease-in-out;
}



.description h2 {
    color: black;
    font-family: 'Raleway', Sans-Serif, serif;
    font-weight: 500;
    font-size: 30px;
    margin: 0;

    @media screen and (max-width: 1000px) {
        font-size: 25px;
    }
}

.description h3 {
    font-family: 'Open Sans', Sans-Serif, serif;
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    padding-top: 10px;
    line-height: 1.5;

    @media screen and (max-width: 1000px) {
        font-size: 15px;
    }
}

.description h4 {
    font-family: 'Open Sans', Sans-Serif, serif;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    line-height: 1.5;

    @media screen and (max-width: 1000px) {
        font-size: 13px;
    }
}

.container {
    height: 100%;
    overflow: hidden;
}

.container ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    list-style: none;
}

.container ul li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
}

.container ul li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: $color-text;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}