@import "./../App.scss";

.overlaid {
  width: 80%;
  max-width: 1000px;
  display: inline-block;
  position: relative;
  margin-top: 7vh;

  @media screen and (max-width: 1000px) {
    margin-top: 1vh;
  }
}

.position {
  top: 50%;
  z-index: 4;

  @media screen and (min-width: 1000px) {
    position: absolute;
    max-width: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .25s ease-in-out;
  }

  @media screen and (max-width: 1000px) {
    -webkit-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
    transition: all .25s ease-in-out;
  }
}

.expandable {
  width: 450px;

  @media screen and (min-width: 1000px) {
    height: 135px;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.preview {
  z-index: 3;
  left: 0;
  -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.16), 0 0 2px rgba(0,0,0,.08);
  box-shadow: 0 2px 4px rgba(0,0,0,.16), 0 0 2px rgba(0,0,0,.08);
  width: 62%;
  padding-top: 34.875%;
  height: 0;
  position: relative;

  @media screen and (max-width: 1000px) {
    margin: auto;
    width: 100%;
    height: 25vh;
  }
}

.preview img {
  user-select: none;
  position: absolute;
  padding: 15%;
  width:80%;
  height:100%;
  object-fit: contain;
  top: 0;
  transition: .25s ease;
}

.flexbox-centering {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  background: linear-gradient(to top, $color-text 0%, #3cb2ff 100%);
  transition: .25s ease;
}

.light {
  opacity: .4;
}

.heavy {
  opacity: .6;
}

.preview:hover .color {
  opacity: 0;
}

.preview:hover img {
  opacity: 0;
}

.round {
  border-radius: 16px;
}

.technology {
  display: inline-block;
  font-family: "Fira Code", serif;
  font-size: 15px;
  opacity: 0.8;
}

.left .technology {
  margin-right: 15px;
}

.right .technology {
  margin-left: 15px;
}

.tech-stack {
  margin: 10px 0;
}

.project-links a {
  color: black;
}

.project-links i {
  opacity: .7;
  transition: color .2s, opacity .2s;
}

.left .project-links i {
  margin-right: 20px;
  font-size: 20px;
}

.right .project-links i {
  margin-left: 20px;
  font-size: 20px;
}

.project-links i:hover {
  color: $color-text;
  opacity: 1;
}

.project-name {
  color: black;
  font-family: 'Raleway', Sans-Serif, serif;
  font-weight: 600;
  font-size: 30px;
  margin: 10px 0;
}

@media screen and (min-width: 1000px) {
  .overlaid:nth-child(even) .position {
    right: 0;
  }

  .overlaid:nth-child(odd) .position {
    left: 0;
  }

  .overlaid:nth-child(odd) .preview {
    margin-left: 38%;
  }

  .overlaid:nth-child(odd) .fade-right {
    -webkit-animation: fadeInLeft 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInLeft 1s; /* Firefox < 16 */
    -ms-animation: fadeInLeft 1s; /* Internet Explorer */
    -o-animation: fadeInLeft 1s; /* Opera < 12.1 */
    animation: fadeInLeft 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;/* Firefox < 16 */
    -ms-animation-fill-mode: forwards;/* Internet Explorer */
    -o-animation-fill-mode: forwards;/* Opera < 12.1 */
    animation-fill-mode: forwards;
  }

  .expandable:hover {
    height: 95%;
    width: 569px;
    max-width: 80%;
  }
}

.fade-right {
  opacity: 0;
  -webkit-animation: fadeInRight 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeInRight 1s; /* Firefox < 16 */
  -ms-animation: fadeInRight 1s; /* Internet Explorer */
  -o-animation: fadeInRight 1s; /* Opera < 12.1 */
  animation: fadeInRight 1s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;/* Firefox < 16 */
  -ms-animation-fill-mode: forwards;/* Internet Explorer */
  -o-animation-fill-mode: forwards;/* Opera < 12.1 */
  animation-fill-mode: forwards;
}

.project {
  width: 50%;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-40px);
  }
  to   {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to   {
    opacity: 1;
    transform: translateX(0);
  }
}