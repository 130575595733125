@import "./../App.scss";

html, body {
    padding: 0;
    margin: 0;
    height: 100%;
    background: $primary-gradient;
    font-family: Helvetica, sans-serif;
}

header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-top: 18px;
    width: 100%;
    height: 38px;
    z-index: 1;
}

.fade-in {
  opacity: 0;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
       -o-animation: fadein 1s; /* Opera < 12.1 */
          animation: fadein 1s;
  -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;/* Firefox < 16 */
       -ms-animation-fill-mode: forwards;/* Internet Explorer */
        -o-animation-fill-mode: forwards;/* Opera < 12.1 */
           animation-fill-mode: forwards;
}

.fade-in.one {
  -webkit-animation-delay: 0s;
     -moz-animation-delay: 0s;/* Firefox < 16 */
      -ms-animation-delay: 0s;/* Internet Explorer */
       -o-animation-delay: 0s;/* Opera < 12.1 */
          animation-delay: 0s;
}

.fade-in.two {
  -webkit-animation-delay: 0.5s;
     -moz-animation-delay: 0.5s;/* Firefox < 16 */
      -ms-animation-delay: 0.5s;/* Internet Explorer */
       -o-animation-delay: 0.5s;/* Opera < 12.1 */
          animation-delay: 0.5s;
}

.fade-in.three {
  -webkit-animation-delay: 1s;
     -moz-animation-delay: 1s;/* Firefox < 16 */
      -ms-animation-delay: 1s;/* Internet Explorer */
       -o-animation-delay: 1s;/* Opera < 12.1 */
          animation-delay: 1s;
}

.fade-in.four {
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;/* Firefox < 16 */
  -ms-animation-delay: 0.25s;/* Internet Explorer */
  -o-animation-delay: 0.25s;/* Opera < 12.1 */
  animation-delay: 0.25s;
}

.fade-in.five {
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;/* Firefox < 16 */
  -ms-animation-delay: 0.75s;/* Internet Explorer */
  -o-animation-delay: 0.75s;/* Opera < 12.1 */
  animation-delay: 0.75s;
}

.fade-still {
  opacity: 0;
  -webkit-animation: fadestill 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadestill 1s; /* Firefox < 16 */
  -ms-animation: fadestill 1s; /* Internet Explorer */
  -o-animation: fadestill 1s; /* Opera < 12.1 */
  animation: fadestill 1s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;/* Firefox < 16 */
  -ms-animation-fill-mode: forwards;/* Internet Explorer */
  -o-animation-fill-mode: forwards;/* Opera < 12.1 */
  animation-fill-mode: forwards;
}

@keyframes fadestill {
    from {
      opacity: 0;
    }
    to   {
      opacity: 1;
    }
}


@keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to   {
      opacity: 1;
      transform: translateY(0);
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
    }
    to   {
      opacity: 1;
      -moz-transform: translateY(0);
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
    }
    to   {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
      opacity: 0;
      -ms-transform: translateY(20px);
    }
    to   {
      opacity: 1;
      -ms-transform: translateY(0);
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
      opacity: 0;
      -o-transform: translateY(20px);
    }
    to   {
      opacity: 1;
      -o-transform: translateY(0);
    }
}
