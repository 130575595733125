@import "./../App.scss";

.main-block {
    position: relative;
    display: flex;
    width: 100%;
    height: 85vh;
    color: $dark-text;
}

.left-container {
    flex-basis: 60%;
    vertical-align: middle;
}

.right-container {
    flex-basis: 40%;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-text {
    margin: auto;
    text-align: left;
    padding-left: 13%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 1000px){
        padding: 5%;
    }
}

.banner-text h1 {
    color: $color-text;
    font-size: 60px;
    font-family: 'Raleway', Sans-Serif, serif;
    font-weight: 400;
    margin-bottom: 0;
    padding-bottom: 3%;

    @media screen and (max-width: 1000px){
        font-size: 50px;
        padding-bottom: 10%;
    }
}

.bio {
    font-size: 30px;
    font-family: 'Open Sans', Sans-Serif, serif;
    font-weight: 100;
    color: $light-text;
    margin-bottom: 25px;

    @media screen and (max-width: 1000px){
        font-size: 23px;
    }
}

.current-positions {
    font-size: 24px;
    font-family: 'Open Sans', Sans-Serif, serif;
    font-weight: 100;
    color: $light-text;
    padding-bottom: 5%;

    @media screen and (max-width: 1000px){
        font-size: 23px;
        padding-bottom: 10%;
    }
}

.banner-text a {
    text-decoration: none;
}

.influxdata {
    background: linear-gradient(to right, #22ADF6 0%, #9394FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.links {
    display: flex;
    justify-content: space-between;
    width: 40%;

    @media screen and (max-width: 1000px){
        width: 75%;
        align-self: center;

    }
}

.links i {
    font-size: 2em;
    padding-top: 7px;
    padding-bottom: 7px;
}

.arrow-container {
    display: flex;

    @media screen and (max-width: 1000px){
        justify-content: center;
    }
}

.arrow {
    font-size: 2em;
    margin-top: 30px;
}

.arrow i {
    color: $color-text;
}

.arrow:hover {
    transform: translate(0, 5px);
    transition: transform .1s ease-in-out;
    cursor: pointer;
}